import React, { Component } from 'react'
import './App.css';
import Publications from './Publications';
import Sidenav from './Sidenav';
import Navbar from './Navbar';
import MainArea from './MainArea';

/*function App() {

  return (
    <div className="App">
      <Sidenav />
      <Navbar />
      <Publications />
      <MainArea />
    </div>
  );
}

export default App;*/



export class App extends Component {
  render() {
    return (
      <div className="App">
        <Sidenav />
        <Navbar />
        <Publications />
        <MainArea />
      </div>
    );
  }
}

export default App