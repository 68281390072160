import React, { Component } from 'react'

class MainArea extends Component {
  render() {
    return (
        <main id="main">
        </main>
    )
  }
}

export default MainArea