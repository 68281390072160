import React, { Component } from 'react'

class Sidenav extends Component {
  render() {
    return (
        <ul class="sidenav sidenav-fixed" id="slide-out" style={{top:"65px"}}>    
            <li><a class="sidenav-close home"><i class="material-icons left">home</i>Home</a></li>
            <li><a class="sidenav-close publi"><i class="material-icons left">description</i>Publications</a></li>
            <li><a class="sidenav-close code"><i class="material-icons left">folder</i>Useful code</a></li>
            <li><a class="sidenav-close map"><i class="material-icons left">map</i>Map</a></li>
        </ul>
    )
  }
}

export default Sidenav