import React, { Component } from 'react'

class Publications extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isPublications: false
        }
    }

  render() {
    if(this.state.isPublications){
        return (
            <div style={{padding: "0px 20px"}}>
                <p>Visit my <a href="https://orcid.org/0000-0001-6255-2773">ORCID</a> for details.</p>
        
                <div class="divider"></div>
        
                <div class="section">
                    <h5>Good books</h5>
                    <p><a href="">(Underconstruction)</a></p>
                    </div>
            </div>
            )
    }else{
        return null
    }
  }
}

export default Publications